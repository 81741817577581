<template>
  <div>
    <el-card class="box-card">
        <div class="componentName"
      >
        <b>{{ componentData.component_name }}</b>
        </div>
        <el-divider></el-divider>
      <div class="imgage">
        <img src="@/assets/img/celebrate.jpg" height="100" width="150" />
      </div>
     
      <div class="ribbon-div">
             <p class="txt-style">Mar 11,2023 <br>Happy Birthday Radha Alla, have a great year ahead!</p>
     </div>
    <div class="ribbon-div">
   <p class="txt-style">Mar 10,2023<br>Happy Birthday Umar Sheik, have a great year ahead!</p>
     </div>
       <div class="imgage">
        <img src="@/assets/img/celebrate-2.jpg" height="100" width="150" />
      </div>
 <div class="ribbon-div">
   <p class="txt-style">Mar 8,2023<br>Happy Birthday Rasool Sheik, have a great year ahead!</p>
     </div>
      <div class="ribbon-div">
   <p class="txt-style">Mar 6,2023<br>Happy Birthday Prasanna CH, have a great year ahead!</p>
     </div>
      <!-- <div class="text item">
        <i class="el-icon-user-solid"></i>
        Alex Sona has joined us in the company on 11 Mar,2023.
      </div>
      <div class="text item">
        <i class="el-icon-user-solid"></i>
        Congratulations to Umar Sheik on completing 2 successful years
      </div> -->
    </el-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
     getComponentName() {
      if (this.componentData && this.componentData.component_name) {
        return this.componentData.component_name;
      } else {
        let name = "";
        if (this.currentEntity && this.currentEntity.name) {
          name = this.currentEntity.name;
        }
        if (this.currentFilter && this.currentFilter.filter_name) {
          name = name + " - " + this.currentFilter.filter_name;
        }
        return name;
      }
    },
    ...mapGetters("entities", [
      "getEntityCreatedStatus",
      "getEntityCreatedData",

      "getEntityDataById",
      "getEntityDataUpdateStatus",
      "getEntityErrors",
      "getAllEntities",
      "getEntityRecordsForTable"
    ]),
    ...mapGetters("filters", ["getSingleFilterData", "getAllEntityFilters"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"])
  },
  props: {
    component: Object,
    entity_id: String,
    filter_id: String,
    hide_options: Boolean,
    hide_expend: Boolean,
    index: Number,
    customFilters: Array,
    globalVariablesData: Array,
    customDashboardData: Object,
    newFilters: Array
  },
  async mounted() {
    let limit = (this.pageSizes && this.pageSizes[0]) || 5;
    this.pageSize = limit;
    this.componentData = JSON.parse(JSON.stringify(this.component));
  },
  data() {
    return {
      componentData: {},
      currentEntity: null,
      currentFilter: null,
      constantFilter: null,
    };
  }
};
</script>
<style scoped>
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.imgage {
  float: right;
}
.box-card {
  width: 600px;
  height: 400px;
  box-shadow: 3px 3px #f5ecec;
  /* background-image: url('/assets/img/celebrate.jpg'); */
}
/* .container{
     background-image: url('/assets/img/celebrate.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  } */
  .ribbon-div{
     background-color: #6264a8;
     border-radius: 7px;
     height: 55px;
     width: 400px;
     margin-left: 2px;
     margin-bottom: 20px;
  }
  .txt-style{
      color: rgb(248, 244, 244);
      font-weight: 500;
      margin-top:5px;
      margin-left:5px;
  }
</style>